export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Configuration
  configurationRoot: '/configuration',

  // General Settings
  configurationGeneralSettingsRoot: '/configuration/general-settings',
  configurationGeneralSettingsList: '/configuration/general-settings/list',

  // General Settings - Edit
  configurationGeneralSettingsEditRoot: '/configuration/general-settings/edit',
  // General Settings - Edit - PDP Connection
  configurationGeneralSettingsEditPDPConnectionRoot: '/configuration/general-settings/edit/pdp-connection',
  configurationGeneralSettingsEditPDPConnectionClientInformation:
    '/configuration/general-settings/edit/pdp-connection/client-information',
  configurationGeneralSettingsEditPDPConnectionPublish: '/configuration/general-settings/edit/pdp-connection/publish',
  configurationGeneralSettingsEditPDPConnectionRegistrationRoot:
    '/configuration/general-settings/edit/pdp-connection/registration',
  configurationGeneralSettingsEditPDPConnectionRegistrationList:
    '/configuration/general-settings/edit/pdp-connection/registration/list',
  configurationGeneralSettingsEditPDPConnectionRegistrationCreate:
    '/configuration/general-settings/edit/pdp-connection/registration/create',
  configurationGeneralSettingsEditPDPConnectionRegistrationEdit:
    '/configuration/general-settings/edit/pdp-connection/registration/:pdpRegistrationId/edit',
  configurationGeneralSettingsEditPDPConnectionRegistrationView:
    '/configuration/general-settings/edit/pdp-connection/registration/:pdpRegistrationId/view',
  configurationGeneralSettingsEditPDPConnectionPossibleMatchConfiguration:
    '/configuration/general-settings/edit/pdp-connection/possible-match-configuration',
  // General Settings- Edit - Additional options
  configurationGeneralSettingsEditAdditionalOptions: '/configuration/general-settings/edit/additional-options',
  // General Settings - Edit - Complex matching
  configurationGeneralSettingsEditComplexMatchingRoot: '/configuration/general-settings/edit/complex-matching',
  configurationGeneralSettingsEditComplexMatchingPreview:
    '/configuration/general-settings/edit/complex-matching/preview',
  configurationGeneralSettingsEditComplexMatchingEdit: '/configuration/general-settings/edit/complex-matching/edit',
  configurationGeneralSettingsEditComplexMatchingEditRules:
    '/configuration/general-settings/edit/complex-matching/edit/rules',

  // General Settings - View
  configurationGeneralSettingsViewRoot: '/configuration/general-settings/view',
  // General Settings- View - PDP Connection
  configurationGeneralSettingsViewPDPConnectionRoot: '/configuration/general-settings/view/pdp-connection',
  configurationGeneralSettingsViewPDPConnectionClientInformation:
    '/configuration/general-settings/view/pdp-connection/client-information',
  configurationGeneralSettingsViewPDPConnectionPublish: '/configuration/general-settings/view/pdp-connection/publish',
  configurationGeneralSettingsViewPDPConnectionRegistrationRoot:
    '/configuration/general-settings/view/pdp-connection/registration',
  configurationGeneralSettingsViewPDPConnectionRegistrationList:
    '/configuration/general-settings/view/pdp-connection/registration/list',
  configurationGeneralSettingsViewPDPConnectionRegistrationView:
    '/configuration/general-settings/view/pdp-connection/registration/:pdpRegistrationId/view',
  configurationGeneralSettingsViewPDPConnectionPossibleMatchConfiguration:
    '/configuration/general-settings/view/pdp-connection/possible-match-configuration',
  // General Settings- View - Additional options
  configurationGeneralSettingsViewAdditionalOptions: '/configuration/general-settings/view/additional-options',
  // General Settings - View - Complex matching
  configurationGeneralSettingsViewComplexMatchingRoot: '/configuration/general-settings/view/complex-matching',
  configurationGeneralSettingsViewComplexMatchingPreview:
    '/configuration/general-settings/view/complex-matching/preview',
  configurationGeneralSettingsViewComplexMatchingView: '/configuration/general-settings/view/complex-matching/view',

  // Matching configuration
  configurationMatchingRoot: '/configuration/matching',

  // Matching configuration - Policy Main
  configurationMatchingPolicyMain: '/configuration/matching/policy/main',
  configurationMatchingPolicyList: '/configuration/matching/policy/main/list',
  // Matching configuration - Policy Main - Review
  configurationMatchingPolicyReview: '/configuration/matching/policy/main/review/:policyId',
  // Matching configuration - Policy Main - Edit
  configurationMatchingPolicyEditRoot: '/configuration/matching/policy/main/edit/:policyId',
  configurationMatchingPolicyEditPolicyDetails: '/configuration/matching/policy/main/edit/:policyId/details',
  configurationMatchingPolicyEditPolicyMatchRules: '/configuration/matching/policy/main/edit/:policyId/match-rules',
  configurationMatchingPolicyEditPolicyMatchRulesRules:
    '/configuration/matching/policy/main/edit/:policyId/match-rules/:matchingPolicyGroupId',
  configurationMatchingPolicyEditPolicyMatchRulesRulesEditRule:
    '/configuration/matching/policy/main/edit/:policyId/match-rules/:matchingPolicyGroupId/edit/rule/:ruleId',
  configurationMatchingPolicyEditPolicyMatchRulesRulesEditRuleComplexMatchingRule:
    '/configuration/matching/policy/main/edit/:policyId/match-rules/:matchingPolicyGroupId/edit/rule/:ruleId/general-settings',

  configurationMatchingPolicyEditPolicySchemes: '/configuration/matching/policy/main/edit/:policyId/schemes',
  // Matching configuration - Policy Main - View
  configurationMatchingPolicyViewRoot: '/configuration/matching/policy/main/view/:policyId',
  configurationMatchingPolicyViewPolicyDetails: '/configuration/matching/policy/main/view/:policyId/details',
  configurationMatchingPolicyViewPolicyMatchRules: '/configuration/matching/policy/main/view/:policyId/match-rules',
  configurationMatchingPolicyViewPolicySchemes: '/configuration/matching/policy/main/view/:policyId/schemes',
  configurationMatchingPolicyViewPolicyVersionRoot: '/configuration/matching/policy/main/view/:policyId/version',
  configurationMatchingPolicyViewPolicyVersionList: '/configuration/matching/policy/main/view/:policyId/version/list',
  configurationMatchingPolicyViewPolicyVersionDetails:
    '/configuration/matching/policy/main/view/:policyId/version/:versionId',
  // Matching configuration - Policy Main - Create Default
  configurationMatchingDefaultPolicyCreateRoot: '/configuration/matching/policy/create-default',
  configurationMatchingDefaultPolicyCreatePolicyDetails:
    '/configuration/matching/policy/create-default/:policyId/details',
  configurationMatchingDefaultPolicyCreatePolicyMatchRules:
    '/configuration/matching/policy/create-default/:policyId/match-rules',
  configurationMatchingDefaultPolicyCreatePolicyMatchRulesRules:
    '/configuration/matching/policy/create-default/:policyId/match-rules/:matchingPolicyGroupId',
  configurationMatchingDefaultPolicyCreatePolicyMatchRulesRulesEditRule:
    '/configuration/matching/policy/create-default/:policyId/match-rules/:matchingPolicyGroupId/edit/rule/:ruleId',
  configurationMatchingDefaultPolicyCreatePolicySummary:
    '/configuration/matching/policy/create-default/:policyId/summary',
  // Matching configuration - Policy Main - Create
  configurationMatchingPolicyCreateRoot: '/configuration/matching/policy/create',
  configurationMatchingPolicyCreatePolicyDetails: '/configuration/matching/policy/create/:policyId/details',
  configurationMatchingPolicyCreatePolicyMatchRules: '/configuration/matching/policy/create/:policyId/match-rules',
  configurationMatchingPolicyCreatePolicyMatchRulesRules:
    '/configuration/matching/policy/create/:policyId/match-rules/:matchingPolicyGroupId',
  configurationMatchingPolicyCreatePolicyMatchRulesRulesEditRule:
    '/configuration/matching/policy/create/:policyId/match-rules/:matchingPolicyGroupId/edit/rule/:ruleId',
  configurationMatchingPolicyCreatePolicySchemes: '/configuration/matching/policy/create/:policyId/schemes',
  configurationMatchingPolicyCreatePolicySummary: '/configuration/matching/policy/create/:policyId/summary',
  // Matching configuration - Matching Test
  configurationMatchingTestRoot: '/configuration/matching/test',
  configurationMatchingTestMain: '/configuration/matching/test/main',
  configurationMatchingTestViewMemberRoot: '/configuration/matching/test/member',
  configurationMatchingTestViewMemberDetails: '/configuration/matching/test/member/details',
  configurationMatchingTestViewMemberAdministrative: '/configuration/matching/test/member/administrative',
  configurationMatchingTestViewMemberPensionValue: '/configuration/matching/test/member/pension-value',
  configurationMatchingTestViewMemberMatchingDetails: '/configuration/matching/test/member/matching-details',

  // Publish Configuration
  configurationPublishConfigurationRoot: '/configuration/publish-configuration',
  configurationPublishConfigurationPublishChanges: '/configuration/publish-configuration/publish-changes',
  configurationPublishConfigurationAuditRoot: '/configuration/publish-configuration/audit',
  configurationPublishConfigurationAuditList: '/configuration/publish-configuration/audit/list',
  configurationPublishConfigurationAuditDetails: '/configuration/publish-configuration/audit/:auditId/details',

  // Data Management
  dataManagementRoot: '/data-management',
  dataManagementHistoryRoot: '/data-management/history',
  dataManagementHistoryList: '/data-management/history/list',
  dataManagementHistoryFileViewRoot: '/data-management/history/file/:fileId/view',
  dataManagementHistoryFileViewUploadSummary: '/data-management/history/file/:fileId/view/upload-summary',
  dataManagementHistoryFileViewUploadAudit: '/data-management/history/file/:fileId/view/upload-audit',
  dataManagementHistoryFileViewPDPSynchronisation: '/data-management/history/file/:fileId/view/pdp-synchronisation',
  dataManagementUploadSourceData: '/data-management/upload-source-data',
  //Audit
  dataManagementAuditRoot: '/data-management/audit',
  dataManagementAuditList: '/data-management/audit/list',
  dataManagementAuditDetails: '/data-management/audit/:auditId/details',
  // Membership
  dataManagementMemberList: '/data-management/member/list',
  //
  // Membership Edit
  dataManagementMemberEditRoot: '/data-management/member/:membershipId/edit',
  // Membership Edit - Membership Detail
  dataManagementMemberEditDetailRoot: '/data-management/member/:membershipId/edit/membership-detail',
  dataManagementMemberEditDetailPersonal: '/data-management/member/:membershipId/edit/membership-detail/personal',
  dataManagementMemberEditDetailPensionAccount:
    '/data-management/member/:membershipId/edit/membership-detail/pension-account',
  dataManagementMemberEditDetailEmployerHistory:
    '/data-management/member/:membershipId/edit/membership-detail/employment-history',
  dataManagementMemberEditDetailStatus: '/data-management/member/:membershipId/edit/membership-detail/pdp-status',
  // Membership Edit - Pension Value Detail
  dataManagementMemberEditPensionValueRoot: '/data-management/member/:membershipId/edit/pension-value',
  dataManagementMemberEditPensionValueERIRoot: '/data-management/member/:membershipId/edit/pension-value/eri',
  dataManagementMemberEditPensionValueAccruedRoot: '/data-management/member/:membershipId/edit/pension-value/accrued',
  dataManagementMemberEditPensionValueERIList: '/data-management/member/:membershipId/edit/pension-value/eri/list',
  dataManagementMemberEditPensionValueAccruedList:
    '/data-management/member/:membershipId/edit/pension-value/accrued/list',
  dataManagementMemberEditPensionValueERICreate: '/data-management/member/:membershipId/edit/pension-value/eri/create',
  dataManagementMemberEditPensionValueAccruedCreate:
    '/data-management/member/:membershipId/edit/pension-value/accrued/create',
  dataManagementMemberEditPensionValueERIDetails:
    '/data-management/member/:membershipId/edit/pension-value/eri/:pensionId/details',
  dataManagementMemberEditPensionValueAccruedDetails:
    '/data-management/member/:membershipId/edit/pension-value/accrued/:pensionId/details',
  // Membership Edit - Matching Details
  dataManagementMemberEditMatchingDetailsRoot: '/data-management/member/:membershipId/edit/matching-details',
  dataManagementMemberEditMatchingDetailsList: '/data-management/member/:membershipId/edit/matching-details/list',
  dataManagementMemberEditMatchingDetailsDetails:
    '/data-management/member/:membershipId/edit/matching-details/:membershipMatchingId/details',
  // Membership Edit - Audit
  dataManagementMemberEditAuditRoot: '/data-management/member/:membershipId/edit/audit',
  dataManagementMemberEditAuditList: '/data-management/member/:membershipId/edit/audit/list',
  dataManagementMemberEditAuditDetails: '/data-management/member/:membershipId/edit/audit/:auditId/details',

  //
  // Membership View
  dataManagementMemberViewRoot: '/data-management/member/:membershipId/view',
  // Membership View - Membership Detail
  dataManagementMemberViewDetailRoot: '/data-management/member/:membershipId/view/membership-detail',
  dataManagementMemberViewDetailPersonal: '/data-management/member/:membershipId/view/membership-detail/personal',
  dataManagementMemberViewDetailPensionAccount:
    '/data-management/member/:membershipId/view/membership-detail/pension-account',
  dataManagementMemberViewDetailEmployerHistory:
    '/data-management/member/:membershipId/view/membership-detail/employment-history',
  dataManagementMemberViewDetailStatus: '/data-management/member/:membershipId/view/membership-detail/pdp-status',
  // Membership View - Pension Value Detail
  dataManagementMemberViewPensionValueRoot: '/data-management/member/:membershipId/view/pension-value',
  dataManagementMemberViewPensionValueERIRoot: '/data-management/member/:membershipId/view/pension-value/eri',
  dataManagementMemberViewPensionValueAccruedRoot: '/data-management/member/:membershipId/view/pension-value/accrued',
  dataManagementMemberViewPensionValueERIList: '/data-management/member/:membershipId/view/pension-value/eri/list',
  dataManagementMemberViewPensionValueAccruedList:
    '/data-management/member/:membershipId/view/pension-value/accrued/list',
  dataManagementMemberViewPensionValueERICreate: '/data-management/member/:membershipId/view/pension-value/eri/create',
  dataManagementMemberViewPensionValueAccruedCreate:
    '/data-management/member/:membershipId/view/pension-value/accrued/create',
  dataManagementMemberViewPensionValueERIDetails:
    '/data-management/member/:membershipId/view/pension-value/eri/:pensionId/details',
  dataManagementMemberViewPensionValueAccruedDetails:
    '/data-management/member/:membershipId/view/pension-value/accrued/:pensionId/details',
  // Membership View - Matching Details
  dataManagementMemberViewMatchingDetailsRoot: '/data-management/member/:membershipId/view/matching-details',
  dataManagementMemberViewMatchingDetailsList: '/data-management/member/:membershipId/view/matching-details/list',
  dataManagementMemberViewMatchingDetailsDetails:
    '/data-management/member/:membershipId/view/matching-details/:membershipMatchingId/details',
  // Membership View - Audit
  dataManagementMemberViewAuditRoot: '/data-management/member/:membershipId/view/audit',
  dataManagementMemberViewAuditList: '/data-management/member/:membershipId/view/audit/list',
  dataManagementMemberViewAuditDetails: '/data-management/member/:membershipId/view/audit/:auditId/details',

  // Analytics & Reports
  analyticsRoot: '/analytics',
  analyticsPensionFusionInsight: '/analytics/pension-fusion-insight',
  analyticsPossibleMatches: '/analytics/possible-matches',
  analyticsMissingViewValues: '/analytics/missing-view-values',
  analyticsMemberExtract: '/analytics/member-extract',
  analyticsFileExtractAudit: '/analytics/audit',

  // Product Information
  productInfo: '/product-info',

  // Audit
  audit: '/audit',
};

export { GuardedRoute } from './GuardedRoute';

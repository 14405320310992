import { GlobalRole } from '@itm/shared-frontend';

export { ResultFailType } from '@itm/shared-frontend';
export type {
  GlobalRole,
  SelectOption,
  ServerError,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
  // Data Access Permission
  ErrorWithType,
  SchemeFromProductDto,
  ProductDto,
  CreateDataAccessDto,
  ShortSchemeResponse,
  ProductSchemeResponse,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'DashboardISP_Analyst',
  Viewer = 'DashboardISP_Viewer',
  ClientAdmin = 'DashboardISP_ClientAdmin',
  Manager = 'DashboardISP_Manager',
}

export type CompanyResponse = {
  id: string;
  name: string;
};

export type CompanyConfigResponse = {
  id: string;
  name: string;
  pdpPublish: boolean;
  altusClientId: number | null;
  altusUserName: string | null;
  canEditPDPPublish: boolean;
  isAutoDeregisterPossibleMatches: boolean;
  autoDeregisterPossibleMatchesDays: number;
};

export type SchemeResponse = {
  id: string;
  name: string;
  companyId: string;
};

export type SchemeConfigurationResponse = {
  id: string;
  name: string;
  pdpPublish: boolean;
  isPDPRegistered: boolean;
  pdpRegistrationId: string | null;
};

export enum CompanyConfigurationStatus {
  MatchingPolicyCreated = 'MatchingPolicyCreated',
  ClientInformationUpdated = 'ClientInformationUpdated',
}

export type CompanyConfigurationResponse = {
  id: string;
  name: string | null;
  statuses: CompanyConfigurationStatus[];
  pdpPublish: boolean;
  isComplexMatchingEnabled: boolean;
  createdDate: string;
  updatedDate: string | null;
};

export enum ComplexMatchingAlgorithm {
  AllowSimilarValues = 'AllowSimilarValues',
  AllowSwappedNeighbouringCharacters = 'AllowSwappedNeighbouringCharacters',
  AllowSwappedNames = 'AllowSwappedNames',
  IgnoreDoubleBarrelledNames = 'IgnoreDoubleBarrelledNames',
  AllowXDayDateTolerance = 'AllowXDayDateTolerance',
  AllowAmericanDateFormat = 'AllowAmericanDateFormat',
}

export type ComplexMatchingAlgorithmsDto = {
  companyId: string;
  firstNameIdentifiers: ComplexMatchingAlgorithm[];
  lastNameIdentifiers: ComplexMatchingAlgorithm[];
  dobIdentifiers: ComplexMatchingAlgorithm[];
  niNoIdentifiers: ComplexMatchingAlgorithm[];
  postCodeIdentifiers: ComplexMatchingAlgorithm[];
  mobileNumberIdentifiers: ComplexMatchingAlgorithm[];
  emailIdentifiers: ComplexMatchingAlgorithm[];
  comment?: string;
};

export type ComplexMatchingStateResponse = {
  isComplexMatchingEnabled: boolean;
  isComplexMatchingDisableAvailable: boolean;
  canDisableComplexMatching: boolean;
};

export type RuleWithComplexIdentifier = {
  description: string | null;
  isComplex: boolean;
  isDigitalVerified: boolean;
};

export type GetRulesToReviewResponse = {
  matchingPolicyId: string | null;
  matchingPolicyGroupId: string | null;
  matchingRuleId: string | null;
  matchingPolicyVersion: number;
  canEditPolicy: boolean;
  matchingPolicyStatus: MatchingPolicyStatus;
  matchingPolicyVersionStatus: MatchingPolicyApprovalStatus;
  matchingPolicyName: string | null;
  matchingRuleName: string | null;
  schemesNames: string[] | null;
  matchingRuleDetails: RuleWithComplexIdentifier[] | null;
};

export type CompanyConfigurationsWithCount = {
  items: CompanyConfigurationResponse[];
  totalCount: number;
};

export type UpdateCompanyConfigurationDto = {
  companyId: string;
  altusClientId: number;
  altusUserName?: string;
};

export enum AddressTypes {
  Current = 'Current',
  Previous = 'Previous',
  Other = 'Other',
}

export enum AlternativeNameType {
  Changed = 'Changed',
  Maiden = 'Maiden',
}

export enum PensionOrigin {
  P = 'P',
  S = 'S',
  W = 'W',
}

export enum PensionType {
  AVC = 'AVC',
  CB = 'CB',
  CMP = 'CMP',
  DB = 'DB',
  DC = 'DC',
  HYB = 'HYB',
  SP = 'SP',
}

export enum MembershipStatusCode {
  A = 'A',
  I = 'I',
}

export enum PensionStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum MatchType {
  Full = 'Full',
  Maybe = 'Maybe',
}

export enum AdministratorContactPreference {
  Website = 'Website',
  Email = 'Email',
  Phone = 'Phone',
  Mail = 'Mail',
}

export enum AdministratorPhoneNumberType {
  Main = 'Main',
  NonUK = 'NonUK',
  SMS = 'SMS',
  WelshSpeaking = 'WelshSpeaking',
  WhatsApp = 'WhatsApp',
}

export enum TestType {
  FindMatching = 'FindMatching',
  FindMember = 'FindMember',
}

export type MappedAltusFindMatchingDto = {
  matchedRule: MatchedRuleResponse;
  section: SectionResponse;
  clientReference: string | null;
  person: AltusPersonDto;
  estimatedRetirementIncomes: AltusEstimatedRetirementIncomes[] | null;
  accruedAmounts: AltusAccruedAmounts[] | null;
  employerName: string | null;
  employmentEndDate: string | null;
  employmentStartDate: string | null;
  linkReference: string | null;
  name: string | null;
  origin: string | null;
  reference: string | null;
  type: string | null;
  retirementDate: string | null;
  sectionClientReference: string | null;
  startDate: string | null;
  status: string | null;
  unavailabilityReason: string | null;
  unavailabilityReasonDescription: string | null;
};

export type DeregisterMatchingDetailsDto = {
  membershipMatchingId: string;
  companyId: string;
  comment: string;
};

export type DemoteMatchingDetailsDto = DeregisterMatchingDetailsDto;

export type ConfirmMembershipMatchingDto = {
  membershipMatchingId: string;
  companyId: string;
  comment?: string;
};

export type EditMembershipMatchingDto = ConfirmMembershipMatchingDto & {
  adminSourceData: AdminSourceDataDto;
};

export type MemberMath = { findRequestIdentifier: string };

export type AltusPersonDto = {
  addresses: AltusAddressesDto[] | null;
  alternativeNames: AltusAlternativeName[] | null;
  dateOfBirth: string;
  emailAddress: string | null;
  forename: string | null;
  hasNoNationalInsuranceNumber: boolean;
  nationalInsuranceNumber: string | null;
  phoneNumber: string | null;
  surname: string | null;
};

export type AltusAddressesDto = {
  clientIdentifier: string | null;
  type: AltusAddressType;
  countryCode: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  line4: string | null;
  line5: string | null;
  postcode: string | null;
  isAddressAsserted: boolean;
  removed: boolean;
  lastUpdated: string | null;
};

export type AltusAlternativeName = {
  clientReference: string | null;
  type: string | null;
  name: string | null;
  isNameAsserted: boolean;
  lastUpdated: string | null;
  removed: boolean;
};

export type AltusEstimatedRetirementIncomes = {
  clientReference: string | null;
  pensionClientReference: string | null;
  amount: number | null;
  amountType: string | null;
  basis: string | null;
  calculationDate: string | null;
  endDate: string | null;
  hasSurvivorBenefits: boolean | null;
  hasSafeguardedBenefits: boolean | null;
  monthlyAmount: number | null;
  payableDate: string | null;
  pensionType: string | null;
  pot: number | null;
  unavailabilityReason: string | null;
  warnings: string[] | null;
  willIncrease: boolean | null;
};

export type AltusAccruedAmounts = {
  clientReference: string | null;
  pensionClientReference: string | null;
  amountType: string | null;
  annualAmount: number | null;
  basis: string | null;
  calculationDate: string | null;
  endDate: string | null;
  hasSurvivorBenefits: boolean | null;
  hasSafeguardedBenefits: boolean | null;
  monthlyAmount: number | null;
  payableDate: string | null;
  pensionType: string | null;
  pot: number | null;
  unavailabilityReason: string | null;
  warnings: string[] | null;
  willIncrease: boolean | null;
};

export type MatchedRuleResponse = {
  matchType: string | null; //on the MappedAltusFindMatchingDto, this field is compared with MatchType enum
  ruleDisplayName: string | null;
  ruleClientReference: string | null;
  ruleId: number | null;
  ruleSetDisplayName: string | null;
  ruleSetClientReference: string | null;
  ruleSetId: number | null;
  types: string[] | null;
};

export type SectionResponse = {
  administratorContactDetails: AdministratorContactDetailsResponse;
  adminContactDetailsClientReference: string | null;
  annualReportUrl: string | null;
  clientReference: string | null;
  costsAndChargesUrl: string | null;
  implementationStatementUrl: string | null;
  investmentPrincipalsUrl: string | null;
  schemeClientReference: string | null;
};

export type AdministratorContactDetailsResponse = {
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  addressLine4: string | null;
  addressLine5: string | null;
  clientReference: string | null;
  contactPreference: string | null; //on the MappedAltusFindMatchingDto, this field is compared with AdministratorContactPreference enum
  email: string | null;
  name: string | null;
  postalName: string | null;
  postcode: string | null;
  url: string | null;
  administratorPhoneNumbers: AdministratorPhoneNumber[] | null;
};

export type AdministratorPhoneNumber = {
  phoneNumber: string | null;
  phoneNumberType: AdministratorPhoneNumberType;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export type AuditResponse = {
  id: string;
  userName: string;
  userId: string;
  eventType: string;
  entityType: string;
  dateTime: string;
  oldValues: string;
  newValues: string;
  affectedColumn: string;
  entityId: string;
};

export type GetAuditResponse = {
  totalCount: number;
  auditRecords: AuditResponse[];
};

export type UserPermissionResponse = {
  userId: string;
  companyId: string;
  companyName: string;
  schemeId: string;
  schemeName: string;
  productId: string;
  productName: string;
  productRoleId: string;
  productRoleName: GlobalRole;
  userProductRoleId: string;
  assignmentDate: string;
};

export enum MatchingPolicyType {
  Template = 'Template',
  Default = 'Default',
  Alternative = 'Alternative',
}

export enum MatchingPolicyStatus {
  Draft = 'Draft',
  Active = 'Active',
  ApprovalPending = 'ApprovalPending',
}

export type MatchingPolicyDetailsResponse = {
  id: string;
  name: string;
  version: number;
  description: string | null;
};
export type MatchingPolicyResponse = {
  id: string;
  name: string;
  status: MatchingPolicyStatus;
  type: MatchingPolicyType;
  version: number;
  description: string | null;
  createdDate: string;
  updatedDate: string | null;
  canEditPolicy: boolean;
  lastPendingVersionId: string | null;
};

export type MatchingPolicyResponseWithCount = {
  totalCount: number;
  items: MatchingPolicyResponse[];
  canViewButtonReview: boolean;
  canCreateNewPolicy: boolean;
};

export enum MatchingPolicyApprovalStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type MatchingPolicyVersionResponse = {
  id: string;
  status: MatchingPolicyApprovalStatus;
  version: number;
  createdByUserName: string | null;
  reviewerUserName: string | null;
  createdDate: string;
  updatedDate: string | null;
};

export type MatchingPolicyVersionsWithCount = {
  items: MatchingPolicyVersionResponse[];
  totalCount: number;
};

export type CreateDraftPolicyDto = {
  companyId: string;
  matchingPolicyId: string;
};

export type ConfirmDraftPolicyDto = {
  companyId: string;
  matchingPolicyVersionId: string;
  comment: string;
};

export type DeleteDraftPolicyDto = {
  companyId: string;
  matchingPolicyVersionId: string;
};

export type MatchingPolicyApproveModel = {
  companyId: string;
  matchingPolicyVersionId: string;
  comment?: string;
};

export type MatchingPolicyRejectModel = MatchingPolicyApproveModel;

export type SchemePolicyResponse = {
  schemeId: string;
  schemeName: string | null;
  matchingPolicyName: string | null;
  updateDate: string;
};

export type SchemeByMatchingPolicyResponseWithCount = {
  items: SchemePolicyResponse[];
  totalCount: number;
};

export enum MatchingRuleCriteriaType {
  Surname = 'Surname',
  Forename = 'Forename',
  DOB = 'DOB',
  NINO = 'NINO',
  Postcode = 'Postcode',
  Email = 'Email',
  Mobile = 'Mobile',
}

export enum MatchingRuleCriteriaComparisonType {
  Exact = 'Exact',
  ExactToAny = 'ExactToAny',
  ExactToCurrent = 'ExactToCurrent',
  ExactAndValid = 'ExactAndValid',
  ExactFirstCharacter = 'ExactFirstCharacter',
  ExactFirst8Characters = 'ExactFirst8Characters',
  ExactFirst8CharactersAndValid = 'ExactFirst8CharactersAndValid',
  ExactLast10Numbers = 'ExactLast10Numbers',
  Fuzzy = 'Fuzzy',
  FuzzyToAny = 'FuzzyToAny',
  ExactFirstCharacterToCurrent = 'ExactFirstCharacterToCurrent',
  ExactFirstCharacterToAny = 'ExactFirstCharacterToAny',
  FuzzyToCurrent = 'FuzzyToCurrent',
  FuzzyAndValid = 'FuzzyAndValid',
  FuzzyFirst8Characters = 'FuzzyFirst8Characters',
  FuzzyFirst8CharactersAndValid = 'FuzzyFirst8CharactersAndValid',
  FuzzyLast10Numbers = 'FuzzyLast10Numbers',
}

export type MatchingRuleCriteriaResponse = {
  id: string;
  type: MatchingRuleCriteriaType;
  comparisonType: MatchingRuleCriteriaComparisonType;
  mustBeVerifiedByDigitalIdentityService: boolean;
};

export type MatchingRuleResponse = {
  id: string;
  name: string;
  order: number;
  isEnabled: boolean;
  matchingRuleTemplateId: string;
  matchingRuleCriteries: MatchingRuleCriteriaResponse[];
};

export type CriteriaComparisonTypeResponse = {
  comparisonType: MatchingRuleCriteriaComparisonType;
  description: string | null;
  isDigitalVerified: boolean | null;
};

export type ComparisonTypeTemplatesResponse = {
  type: MatchingRuleCriteriaType;
  comparisonTypes: CriteriaComparisonTypeResponse[] | null;
};

export enum MatchingPolicyGroupType {
  Full = 'Full',
  Possible = 'Possible',
}
export type MatchingPolicyGroupTemplateResponse = {
  type: MatchingPolicyGroupType;
  matchingRules: MatchingRuleResponse[];
};

export type MatchingPolicyGroupResponse = {
  id: string;
  type: MatchingPolicyGroupType;
  matchingRules: MatchingRuleResponse[];
};

export type CreatePolicyDto = {
  name: string;
  type: string;
  companyId: string;
  description?: string;
};

export type MatchingPolicyCreateResponse = {
  matchingPolicyId: string;
  matchingPolicyVersionId: string;
};

export type MatchingRuleCriteriaDto = {
  type: MatchingRuleCriteriaType;
  comparisonType: MatchingRuleCriteriaComparisonType;
  mustBeVerifiedByDigitalIdentityService: boolean;
};

export type CreateMatchingRuleDto = {
  matchingPolicyGroupId: string;
  name: string;
  matchingRuleCriteries: MatchingRuleCriteriaDto[];
  companyId: string;
};

export type UpdateMatchingRuleDto = {
  name: string;
  matchingRuleCriteries: MatchingRuleCriteriaDto[];
  companyId: string;
};

export type ConfirmPolicyDto = {
  policyId: string;
  companyId: string;
  schemeIds?: string[];
  comment?: string;
};

export type AddRuleToPolicyDto = {
  policyId: string;
  companyId: string;
  fullMatchingRules: string[];
  possibleMatchingRules: string[];
};

export type MatchingPolicyWithRuleResponse = {
  id: string;
  name: string;
  status: MatchingPolicyStatus;
  version: number;
  description: string | null;
  type: MatchingPolicyType;
  matchingPolicyGroups: MatchingPolicyGroupResponse[];
  canEditPolicy: boolean;
  isSelfApproval: boolean;
};

export type MatchingPolicyVersionWithRuleResponse = {
  id: string;
  matchingPolicyId: string;
  matchingPolicyName: string;
  matchingPolicyType: MatchingPolicyType;
  createdByUserId: string | null;
  reviewerUserId: string | null;
  status: MatchingPolicyApprovalStatus;
  version: number;
  createdByUserName: string | null;
  reviewerUserName: string | null;
  createdDate: string;
  updatedDate: string | null;
  creatorComment: string | null;
  reviewerComment: string | null;
  matchingPolicyGroups: MatchingPolicyGroupResponse[];
  canEditPolicy: boolean;
};

export type MatchingPolicyVersionDetailsResponse = {
  id: string;
  status: MatchingPolicyApprovalStatus;
  version: number;
  matchingPolicyName: string;
  createdByUserName: string | null;
  reviewerUserName: string | null;
  createdDate: string;
  updatedDate: string | null;
  creatorComment: string | null;
  reviewerComment: string | null;
  reviewers: string[] | null;
  matchingPolicyGroups: MatchingPolicyGroupResponse[];
  isEnabledToApproval: boolean;
};

export enum SearchMatchingStatus {
  FullMatch = 'FullMatch',
  PossibleMatch = 'PossibleMatch',
  FullMatchAndPossibleMatch = 'FullMatchAndPossibleMatch',
  NoMatch = 'NoMatch',
}

export enum SearchPensionValue {
  ERIAvailable = 'ERIAvailable',
  ERIMissing = 'ERIMissing',
  AccruedPensionAvailable = 'AccruedPensionAvailable',
  AccruedPensionMissing = 'AccruedPensionMissing',
  BothAvailable = 'BothAvailable',
  BothMissing = 'BothMissing',
}

export enum AltusAddressType {
  C = 'C',
  O = 'O',
  P = 'P',
}

// TODO sync with BE
export enum SectionPensionStatus {
  IWU = 'IWU',
  IPPF = 'IPPF',
}
export enum AdditionalSchemeType {
  OTH = 'OTH',
  FOR = 'FOR',
}

export type MembershipAccountDetailsDto = {
  sourceMembershipID: string | null;
  pensionRef: string | null;
  pensionName: string | null;
  pensionType: PensionType;
  pensionOrigin: PensionOrigin;
  pensionStatus: MembershipStatusCode;
  pensionStartDate: string;
  pensionRetirementDate: string | null;
  pensionLink: string | null;
  // TODO sync with BE
  additionalPensionName: string | null;
  additionalPensionType: AdditionalSchemeType;
  sectionPensionStatus: SectionPensionStatus | null;
};

export type AltusTypeBase = {
  type: string;
  description: string;
  altusName: string;
};

export type AvailablePensionTypes = {
  pensionTypes: AltusTypeBase[] | null;
  pensionOrigins: AltusTypeBase[] | null;
  pensionStatuses: AltusTypeBase[] | null;
};

export type MembershipStatuses = {
  pensionStatus: PensionStatus;
  pensionType: PensionType;
};

export type MemberSearchResponse = {
  membershipId: string;
  membershipSrcId: string | null;
  pensionRef: string | null;
  firstName: string | null;
  lastName: string | null;
  nino: string | null;
  dateOfBirth: string | null;
  schemeName: string | null;
  matchingStatus: SearchMatchingStatus;
  pdpMemberReference: string | null;
  pensionValue: SearchPensionValue;
  pdpStatus: PDPStatus;
};

export type MemberSearchResponsePagedResponse = {
  totalCount: number;
  items: MemberSearchResponse[];
};

export type AltnameDto = {
  altnameId: string;
  alternateFirstName?: string | null;
  alternateLastName?: string;
  alternateFullName?: string | null;
  alternateNameType?: AlternativeNameType;
  removed?: boolean;
};

export type MembershipAddressDto = {
  addressId: string;
  addressType: AddressTypes;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  addressLine5?: string;
  country: string;
  postCode: string;
};

export type MembershipDetailDto = {
  companyId?: string | null;
  membershipId?: string | null;
  pensionRef?: string | null;
  membershipSrcId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  dateOfBirth: string;
  email?: string | null;
  mobile?: string | null;
  alternates?: AltnameDto[];
  addresses?: MembershipAddressDto[];
  nino?: string | null;
  nationalInsuranceNumberIsNotApplicable: boolean;
  updateComment: string;
};

export enum PDPStatus {
  Active = 'Active',
  NEW = 'NEW',
  CONT = 'CONT',
  WU = 'WU',
  PPF = 'PPF',
  CALL = 'CALL',
}

export type MembershipPDPStatusResponse = {
  pdpAvailable: boolean;
  pdpStatus: PDPStatus;
  pdpStatusEditComment?: string | null;
};

export type EditPDPStatusDto = {
  membershipId: string;
  companyId: string;
  pdpAvailable?: boolean;
  pdpStatus?: PDPStatus;
  pdpStatusEditComment?: string;
};

export enum MembershipMatchingStatus {
  Active = 'Active',
  Expired = 'Expired',
  Deregistered = 'Deregistered',
  Demoted = 'Demoted',
  Investigating = 'Investigating',
}

export type MatchingDetailsDto = {
  membershipMatchingId: string;
  membershipId: string;
  status: MembershipMatchingStatus;
  matchType: MatchingPolicyGroupType;
  peiNo: string | null;
  pdpMemberReference: string | null;
  matchingDate: string;
  expiryDate: string | null;
  fullName: string | null;
};

export type DashboardAddressData = {
  countryCode?: string | null;
  line1: string | null;
  line2?: string | null;
  line3?: string | null;
  line4?: string | null;
  line5?: string | null;
  postcode: string | null;
  addressType: AddressTypes | null;
};

export type DashboardDataDto = {
  firstName: string | null;
  lastName: string | null;
  addresses: DashboardAddressData[];
  dob: string | null;
  niNo?: string | null;
  email?: string | null;
  mobileNumber?: string | null;
  alternateSurnames?: string[] | null;
};

export type AdminSourceDataDto = DashboardDataDto;

export enum PDPsynchronised {
  Awaiting = 'Awaiting',
  Success = 'Success',
  Failed = 'Failed',
  Aborted = 'Aborted',
  DirectUpdate = 'DirectUpdate',
  NotAvailable = 'NotAvailable',
  NotStarted = 'NotStarted',
}

export type MembershipAuditDto = {
  id: string;
  schemeId: string;
  userName: string | null;
  changeDate: string;
  method: string | null;
  pdPsynchronised: PDPsynchronised;
  schemeName: string | null;
  pensionReference: string | null;
};

export type MembershipAuditDtoPagedResponse = {
  totalCount: number;
  items: MembershipAuditDto[];
};

export type DataChangeAuditInfo = {
  uploadId: string | null;
  pensionReference: string | null;
  schemeName: string | null;
  dataChangeAuditUserName: string | null;
  dataChangeAuditChangeDate: string;
  comments: string | null;
};

export type PDPSynchronisationHistoryResponse = {
  batchId: string | null;
  type: AltusBatchType;
  status: PDPsynchronised;
  message: string | null;
  createdDate: string;
  syncDate: string | null;
};

export type MembershipHistoryResponse = {
  membershipId: string;
  personId: string;
  sectionId: string;
  membershipSrcId: string | null;
  displayRef: string | null;
  statusCode: string | null;
  dateJoinedScheme: string;
  targetRetirementDate: string | null;
  pensionLink: string | null;
  inaccCode: string | null;
};
export enum DataChangeAction {
  Insert = 'Insert',
  Update = 'Update',
  Delete = 'Delete',
}
export type MembershipHistoryResponseHistoryResponse = {
  previousValue: MembershipHistoryResponse | null;
  updatedValue: MembershipHistoryResponse | null;
  action: DataChangeAction;
};

export type EmploymentsHistoryResponse = {
  employmentId: string;
  membershipId: string;
  employer: string | null;
  empStart: string | null;
  empEnd: string | null;
};

export type EmploymentsHistoryResponseHistoryResponse = {
  previousValue: EmploymentsHistoryResponse | null;
  updatedValue: EmploymentsHistoryResponse | null;
  action: DataChangeAction;
};

export type AccruedbenHistoryResponse = {
  accruedbenId: string;
  membershipId: string;
  benefitType: string | null;
  amountType: string | null;
  calcType: string | null;
  calcDate: string | null;
  payableDate: string | null;
  annualAmount: string | null;
  monthlyAmount: string | null;
  incomeEnd: string | null;
  increasing: boolean | null;
  hasSurvivorBen: boolean | null;
  pot: number | null;
  safeguarded: boolean | null;
  unavailCode: string | null;
  warnings: string | null;
};

export type AccruedbenHistoryResponseHistoryResponse = {
  previousValue: AccruedbenHistoryResponse | null;
  updatedValue: AccruedbenHistoryResponse | null;
  action: DataChangeAction;
};

export type EriHistoryResponse = {
  eriId: string;
  membershipId: string;
  benefitType: string | null;
  amountType: string | null;
  eriType: string | null;
  illustDate: string | null;
  payableDate: string | null;
  annualAmount: string | null;
  monthlyAmount: string | null;
  incomeEnd: string | null;
  increasing: string | null;
  hasSurvivorBen: boolean | null;
  pot: number | null;
  safeguarded: boolean | null;
  unavailCode: string | null;
  warnings: string | null;
};

export type EriHistoryResponseHistoryResponse = {
  previousValue: EriHistoryResponse | null;
  updatedValue: EriHistoryResponse | null;
  action: DataChangeAction;
};

export type MembershipMatchingsHistoryResponse = {
  membershipMatchingId: string;
  membershipId: string;
  matchingPolicyId: string;
  matchingRuleId: string;
  registrationId: number;
  findRequestId: string | null;
  status: string | null;
  matchType: string | null;
  pei: string | null;
  uniqueMemberReference: string | null;
  matchingDate: string;
  expiryDate: string | null;
  comment: string | null;
};

export type MembershipMatchingsHistoryResponseHistoryResponse = {
  previousValue: MembershipMatchingsHistoryResponse | null;
  updatedValue: MembershipMatchingsHistoryResponse | null;
  action: DataChangeAction;
};

export type PersonHistoryResponse = {
  personId: string;
  adminDataSourceId: string;
  personSrcId: string | null;
  forename: string | null;
  surname: string | null;
  dob: string;
  nino: string | null;
  ninoNotapplic: boolean;
  email: string | null;
  mobile: string | null;
};

export type PersonHistoryResponseHistoryResponse = {
  previousValue: PersonHistoryResponse | null;
  updatedValue: PersonHistoryResponse | null;
  action: DataChangeAction;
};

export type AltnameHistoryResponse = {
  altnameId: string;
  personId: string;
  altnameType: string | null;
  altforename: string | null;
  altsurname: string | null;
};

export type AltnameHistoryResponseHistoryResponse = {
  previousValue: AltnameHistoryResponse | null;
  updatedValue: AltnameHistoryResponse | null;
  action: DataChangeAction;
};

export type AddressHistoryResponse = {
  addressId: string;
  personId: string;
  addressType: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  addressLine4: string | null;
  addressLine5: string | null;
  postcode: string | null;
  countryCode: string | null;
};

export type AddressHistoryResponseHistoryResponse = {
  previousValue: AddressHistoryResponse | null;
  updatedValue: AddressHistoryResponse | null;
  action: DataChangeAction;
};

export type SummaryHistoryResponse = {
  membershipHistory: MembershipHistoryResponseHistoryResponse;
  employmentsHistory: EmploymentsHistoryResponseHistoryResponse[] | null;
  accruedbenHistory: AccruedbenHistoryResponseHistoryResponse[] | null;
  eriHistory: EriHistoryResponseHistoryResponse | null;
  membershipMatchingsHistory: MembershipMatchingsHistoryResponseHistoryResponse[] | null;
  personHistory: PersonHistoryResponseHistoryResponse;
  altnameHistory: AltnameHistoryResponseHistoryResponse[] | null;
  addressHistory: AddressHistoryResponseHistoryResponse[] | null;
};

export type MembershipAuditHistoryDto = {
  membershipAuditId: string;
  generalInformation: DataChangeAuditInfo;
  pdpSynchronisationHistory: PDPSynchronisationHistoryResponse | null;
  summaryHistory: SummaryHistoryResponse;
  method: string | null;
};

export type RulesUsedDto = {
  ruleName: string | null;
  types: string[];
};

export enum MembershipMatchingType {
  Full = 'Full',
  Possible = 'Possible',
}

export type MembershipMatchingDetailsDto = {
  membershipMatchingId: string;
  membershipId: string;
  peiNo: string | null;
  pdpMemberReference: string | null;
  matchingPolicyName: string | null;
  matchingType: MembershipMatchingType;
  rulesUsed: RulesUsedDto[] | null;
  dashboardData: DashboardDataDto;
  adminSourceData: AdminSourceDataDto;
  altusError: string | null;
  fullName: string | null;
};

export enum MatchResolutionType {
  Overdue = 'Overdue',
  Pending = 'Pending',
  Resolved = 'Resolved',
}

export type PossibleMatchSearchResponse = {
  membershipId: string;
  schemeName: string | null;
  pei: string | null;
  pensionReference: string | null;
  pdpMemberReference: string | null;
  matchingDate: string;
  expireDate: string;
  resolutionType: MatchResolutionType;
};

export type PossibleMatchResponseWithCount = {
  totalCount: number;
  items: [];
};

export type AdminDataSourceDto = {
  id: string;
  name: string | null;
};

export type FindRequestStatistic = {
  timestamp: string;
  count: number;
};

export type FindAndViewRequestsStatistic = {
  timestamp: string;
  countFind: CountFindByOutcome;
  countView: number;
};

export type CountFindByOutcome = {
  full: number;
  possible: number;
};

export type MembershipAnalyticsResponse = {
  totalMembers: number;
  totalSchemes: number;
  statuses: MemberCountByGroup[];
  pensionTypes: MemberCountByGroup[];
};

export type MemberCountByGroup = {
  name: string | null;
  totalMembers: number;
};

export type GetAnalyticsModel = {
  companyId: string;
  schemeId?: string;
};

export enum UploadType {
  SFTP = 'SFTP',
  API = 'API',
  Manual = 'Manual',
}

export enum DataUploadStatusDto {
  InProgress = 'InProgress',
  UploadSuccessful = 'UploadSuccessful',
  PartiallyUploaded = 'PartiallyUploaded',
  UploadFailed = 'UploadFailed',
}

export type DataUploadDto = {
  id: string;
  name: string | null;
  uploadType: UploadType;
  uploadedDate: string;
  uploadedBy: string | null;
  status: DataUploadStatusDto;
};

export type DataUploadDtosWithCount = {
  totalCount: number;
  items: DataUploadDto[];
};

export type DataUploadSchemeDto = {
  id: string;
  name: string;
};

export type DataUploadSectionDto = {
  id: string;
  name: string;
};

export enum DataUploadStatus {
  RawDataReceived = 'RawDataReceived',
  RawDataMappedToSQL = 'RawDataMappedToSQL',
  MappingProjectRunning = 'MappingProjectRunning',
  StrictApiInProgress = 'StrictApiInProgress',
  PartiallyUploaded = 'PartiallyUploaded',
  FullyUploaded = 'FullyUploaded',
  Failed = 'Failed',
}
export type DataUploadAuditDto = {
  id: string;
  status: DataUploadStatus;
  description: string | null;
  timestamp: string;
};

export type DataUploadDetailsDto = {
  id: string;
  name: string | null;
  uploadType: UploadType;
  uploadedDate: string;
  uploadedBy: string | null;
  schemesAffected: string;
  membersProcessedFully: number | null;
  membersProcessedPartially: number | null;
  membersFailed: number | null;
  totalMembers: number | null;
  status: DataUploadStatusDto;
  processedDate: string | null;
  dataUploadAudits: DataUploadAuditDto[] | null;
};

export enum DataUploadValidationResult {
  Blocked = 'Blocked',
  NotBlocked = 'Not blocked',
}

export type DataUploadCheckHit = {
  dataUploadId: string;
  personSrcId: string | null;
  membershipSrcId: string | null;
  displayRef: string | null;
  schemeName: string | null;
  sectionName: string | null;
  areaValidated: string | null;
  fieldValidated: string | null;
  description: string | null;
  result: DataUploadValidationResult | null;
};

export type DataUploadCheckHitsWithCount = {
  totalCount: number;
  items: DataUploadCheckHit[];
};

export enum AltusSyncStatus {
  NotStarted = 'NotStarted',
  PendingProcessing = 'PendingProcessing',
  Processed = 'Processed',
  Failed = 'Failed',
  Aborted = 'Aborted',
  None = 'None',
}

export enum AltusBatchType {
  Pensions = 'Pensions',
  Valuations = 'Valuations',
}

export type SearchDataUploadSyncResponse = {
  batchId: string;
  type: AltusBatchType;
  status: AltusSyncStatus;
  message: string | null;
  totalRecords: number;
  createdDate: string;
  syncDate: string | null;
};

export type DataUploadSyncWithCount = {
  totalCount: number;
  items: SearchDataUploadSyncResponse[];
};

export enum EriType {
  BS = 'BS',
  SMPI = 'SMPI',
}

export enum BenefitType {
  AVC = 'AVC',
  CBS = 'CBS',
  CDI = 'CDI',
  CDL = 'CDL',
  DB = 'DB',
  DBL = 'DBL',
  DC = 'DC',
  HYB = 'HYB',
  UNP = 'UNP',
}

export enum AmountType {
  CSH = 'CSH',
  CSHL = 'CSHL',
  CSHN = 'CSHN',
  INC = 'INC',
  INCL = 'INCL',
  INCN = 'INCN',
}

export enum CalcType {
  BS = 'BS',
  SM = 'SM',
  SMPI = 'SMPI',
}

export enum UnavailCode {
  ERR = 'ERR',
  MAN = 'MAN',
  TRN = 'TRN',
  EXC = 'EXC',
}

export enum WarningCode {
  AVC = 'AVC',
  OTH = 'OTH',
  PAR = 'PAR',
  PSO = 'PSO',
}

export type EriShortDto = {
  id: string;
  benefitType: BenefitType;
  amountType: AmountType;
  eriType: EriType;
  payableDate: string | null;
  annualAmount: number | null;
  monthlyAmount: number | null;
  unavailCode: UnavailCode | null;
  unavailDescription: string | null;
};

export type EriWarningDto = {
  id: string;
  warningCode: WarningCode;
  description: string | null;
};

export type EriDetailsDto = {
  id: string;
  pensionStatus: PensionStatus;
  pensionType: PensionType;
  benefitType: BenefitType;
  amountType: AmountType;
  eriType: EriType;
  payableDate: string | null;
  annualAmount: number | null;
  monthlyAmount: number | null;
  illustDate: string | null;
  incomeEnd: string | null;
  increasing: boolean | null;
  hasSurvivorBen: boolean | null;
  pot: number | null;
  safeguarded: boolean | null;
  unavailCode: UnavailCode | null;
  unavailDescription: string | null;
  eriWarnings: EriWarningDto[] | null;
};

export type EriWarningUpdateDto = {
  warningCode: WarningCode;
};

export type EriUpdateDto = {
  id: string;
  companyId: string;
  benefitType: BenefitType;
  amountType: AmountType;
  eriType: EriType;
  illustDate: string;
  payableDate: string;
  annualAmount?: number;
  monthlyAmount?: number;
  incomeEnd?: string;
  increasing?: boolean;
  hasSurvivorBen?: boolean;
  pot?: number;
  safeguarded: boolean;
  updateComment: string;
  eriWarnings?: EriWarningUpdateDto[];
};

export type EriCreateDto = Omit<EriUpdateDto, 'id' | 'companyId' | 'updateComment'>;

export type BulkCreateEriDto = {
  companyId: string;
  membershipId: string;
  eriCreateDtos: EriCreateDto[];
};

export type AccruedbenShortDto = {
  id: string;
  benefitType: BenefitType;
  amountType: AmountType;
  calcType: CalcType;
  payableDate: string | null;
  annualAmount: number | null;
  monthlyAmount: number | null;
  unavailCode: UnavailCode | null;
  unavailDescription: string | null;
};

export type AccruedbenWarningDetailsDto = EriWarningDto;

export type AccruedbenDetailsDto = {
  id: string;
  benefitType: BenefitType;
  amountType: AmountType;
  calcType: CalcType;
  payableDate: string | null;
  annualAmount: number | null;
  monthlyAmount: number | null;
  unavailCode: UnavailCode | null;
  unavailDescription: string | null;
  calcDate: string | null;
  incomeEnd: string | null;
  increasing: boolean | null;
  hasSurvivorBen: boolean | null;
  pot: number | null;
  safeguarded: boolean | null;
  accruedbenWarnings: AccruedbenWarningDetailsDto[];
};

export type AccruedbenWarningDto = EriWarningUpdateDto;

export type AccruedbenUpdateDto = {
  id: string;
  companyId: string;
  benefitType: BenefitType;
  amountType: AmountType;
  calcType: CalcType;
  payableDate: string;
  annualAmount?: number;
  monthlyAmount?: number;
  calcDate: string;
  incomeEnd?: string;
  increasing?: boolean;
  hasSurvivorBen: boolean;
  pot?: number;
  safeguarded: boolean;
  updateComment: string;
  accruedbenWarnings?: AccruedbenWarningDto[];
};

export type AccruedbenCreateDto = Omit<AccruedbenUpdateDto, 'id' | 'companyId' | 'updateComment'>;

export type BulkCreateAccruedbenDto = {
  companyId: string;
  membershipId: string;
  accruedbenCreateDtos: AccruedbenCreateDto[];
};

export type EmploymentDto = {
  employer: string;
  empStart: string;
  empEnd: string;
  status: string;
};

export enum ViewDataType {
  ERI = 'ERI',
  AccruedPension = 'AccruedPension',
}

export enum SlaStatus {
  Overdue = 'Overdue',
  DueToday = 'DueToday',
  OnTime = 'OnTime',
}

export type MissingViewValueDto = {
  membershipId: string;
  schemeId: string;
  schemeName: string | null;
  viewDataType: ViewDataType;
  pensionReference: string | null;
  viewDataRequestDate: string;
  dueDataProvidingDate: string;
  slaCountdown: number;
  slaStatus: SlaStatus;
};

export type MissingViewValueDtoPagedResponse = {
  totalCount: number;
  items: MissingViewValueDto[];
};

export enum ReportType {
  FullScheme = 'FullScheme',
  MembershipData = 'MembershipData',
  MiData = 'MiData',
  MatchingResults = 'MatchingResults',
  MissingPensionValueData = 'MissingPensionValueData',
  MembershipDetails = 'MembershipDetails',
}

export enum ExtractStatus {
  Created = 'Created',
  InProgress = 'InProgress',
  Success = 'Success',
  Failed = 'Failed',
}

export type FilterDates = {
  dateFrom: string;
  dateTo: string;
};

export type DataExtractRequest = {
  companyId: string;
  schemeId: string;
  reportType: ReportType;
  filter?: FilterDates;
};

export type SearchDataExtractAuditResponse = {
  id: string;
  schemeId: string;
  userName: string | null;
  reportType: ReportType;
  status: ExtractStatus;
  schemeName: string | null;
  createdDate: string;
  filter: FilterDates | null;
};

export type SearchDataExtractAuditResponsePagedResponse = {
  totalCount: number;
  items: SearchDataExtractAuditResponse[];
};

export enum PDPRegistrationStatus {
  Draft = 'Draft',
  Success = 'Success',
}

export enum PDPRegulatorType {
  FCA = 'FCA',
  TPR = 'TPR',
}

export type PDPRegistrationDto = {
  companyId: string;
  status: PDPRegistrationStatus;
  pdpRegulatorType: PDPRegulatorType;
  schemeIds: string[];
  registrationDisplayName: string;
  customerFacingName?: string;
  partSchemeName?: string;
  registrationCode?: string;
  registrationNumber?: string;
};

export type PDPRegistrationUpdateDto = PDPRegistrationDto & {
  pdpRegistrationId: string;
};

export type PDPRegistrationListItemDto = {
  id: string;
  registrationDisplayName: string | null;
  pdpRegulatorType: PDPRegulatorType;
  status: PDPRegistrationStatus;
  createdDate: string;
  updatedDate: string | null;
};

export type PDPRegistrationListItemDtoPagedResponse = {
  totalCount: number;
  items: PDPRegistrationListItemDto[];
};

export type PDPRegistrationDetailsSchemeDto = {
  id: string;
  name?: string | null;
};

export type PDPRegistrationDetailsDto = {
  companyId: string;
  companyName: string;
  status: PDPRegistrationStatus;
  pdpRegulatorType: PDPRegulatorType;
  schemes: PDPRegistrationDetailsSchemeDto[];
  registrationDisplayName: string | null;
  customerFacingName: string | null;
  partSchemeName: string | null;
  registrationCode: string | null;
  registrationNumber: string | null;
};

export type AutoDeregisterPossibleMatchesModel = {
  days: number;
};

export type MatchingPoliciesPublishChangesResponse = {
  id: string;
  companyId: string | null;
  name: string | null;
  status: MatchingPolicyStatus;
  type: MatchingPolicyType;
  version: string | null;
  description: string | null;
  createdDate: string;
  updatedDate: string | null;
};

export type EnvironmentConfigurationProductResponse = {
  productId: string;
  productName: string | null;
};

export type EnvConfigResponse = {
  id: string;
  name: string | null;
};

export type PublishMatchingPolicyChangesToEnvironmentModel = {
  environmentConfigurationId: string;
  comment: string;
  matchingPoliciesIds: string[];
};

export enum EnvironmentPublishArea {
  MatchingPolicies = 'MatchingPolicies',
}

export enum EnvironmentPublishStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Warning = 'Warning',
  Failed = 'Failed',
}

export type SearchEnvironmentPublishAuditResponse = {
  id: string;
  companyId: string | null;
  area: EnvironmentPublishArea;
  status: EnvironmentPublishStatus;
  userName: string | null;
  companyName: string | null;
  originEnvironment: string | null;
  destinationEnvironment: string | null;
  createDate: string;
};

export type SearchEnvironmentPublishAuditResponsePagedResponse = {
  totalCount: number;
  items: SearchEnvironmentPublishAuditResponse[] | null;
};

export type AuditPolicyResponse = {
  id: string;
  name: string | null;
  type: MatchingPolicyType;
  version: number;
};

export type EnvironmentPublishAuditResponse = SearchEnvironmentPublishAuditResponse & {
  comments: string | null;
  errors: string | null;
  policies: AuditPolicyResponse[] | null;
};

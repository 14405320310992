import classNames from 'classnames';
import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import {
  selectedCompanyIdSelector,
  allCompanyOptionsSelector,
  isLoadingCompanySelector,
  isOneCompanyUserSelector,
} from 'src/store/selectors/companySelector';
import { selectCompany } from 'src/store/slices/companySlice';

import { dropdownFilterOption } from '@itm/shared-frontend/lib/utils';
import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';

import { SelectOption } from 'src/types';

function CompanyFilter() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isOneCompanyUser = useSelector(isOneCompanyUserSelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);
  const isLoadingCompanies = useSelector(isLoadingCompanySelector);

  const isFilterAvailable = useMemo(
    () =>
      !isOneCompanyUser &&
      Boolean(
        matchPath(RoutePath.configurationMatchingTestMain, pathname) ||
          matchPath(RoutePath.configurationMatchingPolicyList, pathname) ||
          matchPath(RoutePath.configurationPublishConfigurationPublishChanges, pathname) ||
          matchPath(RoutePath.configurationPublishConfigurationAuditList, pathname) ||
          matchPath(RoutePath.dataManagementHistoryList, pathname) ||
          matchPath(RoutePath.dataManagementAuditList, pathname) ||
          matchPath(RoutePath.dataManagementUploadSourceData, pathname) ||
          matchPath(RoutePath.dataManagementMemberList, pathname) ||
          matchPath(`${RoutePath.analyticsRoot}/*`, pathname) ||
          // Configuration company details
          (matchPath(`${RoutePath.configurationGeneralSettingsEditRoot}/*`, pathname) &&
            !matchPath(RoutePath.configurationGeneralSettingsEditPDPConnectionRegistrationEdit, pathname) &&
            !matchPath(RoutePath.configurationGeneralSettingsEditPDPConnectionRegistrationView, pathname)) ||
          (matchPath(`${RoutePath.configurationGeneralSettingsViewRoot}/*`, pathname) &&
            !matchPath(RoutePath.configurationGeneralSettingsViewPDPConnectionRegistrationView, pathname)),
      ),
    [isOneCompanyUser, pathname],
  );

  const selectedCompanyOption = useMemo(
    () => companyOptions.find((option) => option.value === selectedCompanyId) || null,
    [companyOptions, selectedCompanyId],
  );

  const companyChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectCompany(newOption.value));
    }
  };

  return (
    <div className="is-flex is-justify-content-center pt-5">
      <div className="control is-fullwidth">
        <Select
          className={classNames('react-select', { 'is-loading': isLoadingCompanies })}
          classNamePrefix="react-select"
          value={selectedCompanyOption}
          options={companyOptions}
          aria-label="Company name"
          placeholder={companyOptions.length || isLoadingCompanies ? 'Company name' : 'No companies'}
          onChange={companyChangeHandler}
          isDisabled={!isFilterAvailable || isLoadingCompanies || !companyOptions.length}
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          filterOption={dropdownFilterOption}
        />
      </div>
    </div>
  );
}

export default CompanyFilter;
